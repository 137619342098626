import { useEffect, useState } from "react"
import axios from "axios"
import { Oval } from "react-loader-spinner"
import { Toast, ToastComponent } from "./components/toast"
import { MdDelete } from "react-icons/md"

const LOCALHOST_URL = "http://localhost:9000"
const PRODUCTION_URL = "https://apis.gsyssolutions.io"
const URL_IN_USE = PRODUCTION_URL

function App() {
  const [volumeAmount, setVolumeData] = useState(0)
  const [selectValue, setSelectValue] = useState("private_key")
  const [selectAddress, setSelectAddress] = useState("")
  const [loading, setLoading] = useState(false)
  const [cronTime, setCronTime] = useState("")
  const [volumeIncreaseInterval, setVolumeIncreaseInterval] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const retrieveFromLocalStorage = (key, defaultValue) => {
    const storedValue = localStorage.getItem(key)
    return storedValue ? JSON.parse(storedValue) : defaultValue
  }
  const [data, setData] = useState(
    retrieveFromLocalStorage("data", []),
  )

  const handleChange = (e) => {
    const value = Number(e.target.value)
    if (value >= 0) {
      setVolumeData(e.target.value)
    } else {
      setVolumeData("")
    }
  }

  const handleSubmit = () => {
    if (!selectValue || !selectAddress) return Toast.warning("Enter all the fields.")
    if (selectValue === "private_key") {
      const newEntry = {
        private_key: selectAddress,
        tokens: data.length > 0 ? [...data[0].tokens] : [{ token_address: "GSYS", balance: 0 }],
      }
      setData(prevData => [...prevData, newEntry])
      window.location.reload()
    } else {
      setData(prevData =>
        prevData.map(entry => ({
          ...entry,
          tokens: [...entry.tokens, { token_address: selectAddress, balance: 0 }],
        })),
      )
    }
    setSelectAddress("")
    window.location.reload()
  }

  const fetchBalances = async () => {
    const updatedData = await Promise.all(data.map(async entry => {
      const tokensWithBalances = await Promise.all(entry.tokens.map(async token => {
        const balance = await balanceApi(entry.private_key, token.token_address)
        return { ...token, balance }
      }))
      return { ...entry, tokens: tokensWithBalances }
    }))

    setData(updatedData)
    localStorage.setItem("data", JSON.stringify(updatedData))
  }

  const balanceApi = async (privateKey, token) => {
    try {
      let balance = await axios.post(
        `${URL_IN_USE}/user/getTokenPrice`,
        {
          privateKey: privateKey,
          tokenAddress: token,
        },
      )
      return balance?.data.formattedTokenBalance
    } catch (e) {
      console.log(e)
    }
  }

  const fetchCronTime = async () => {
    try {
      const response = await axios.get(`${URL_IN_USE}/getCronValue`)
      const cronTimeString = response.data.data
      setCronTime(cronTimeString)
      const parts = cronTimeString.split(" ")
      const minutes = parts[0]
      const hours = parts[1]
      let interval
      if (minutes.startsWith("*/")) {
        interval = `${minutes.substring(2)} minutes`
      } else if (hours.startsWith("*/")) {
        interval = `${hours.substring(2)} hours`
      } else {
        interval = "Unknown"
      }
      setVolumeIncreaseInterval(interval)
    } catch (err) {
      console.error(`error`, err)
      Toast.error("Error fetching cron time")
    }
  }
  console.log(`time is`, volumeIncreaseInterval)

  useEffect(() => {
    localStorage.setItem("data", JSON.stringify(data))
  }, [data])

  useEffect(() => {
    fetchCronTime()
    fetchBalances()
    // eslint-disable-next-line
  }, [])

  const stopCron = async () => {
    let response
    try {
      console.log(`in stop cron`)
      setIsLoading(true)
      response = await axios.get(`${URL_IN_USE}/stop`)
      console.log("Response", response.data)
      if (response.data) {
        setIsLoading(false)
        return Toast.success(response.data.message)
      }
    } catch (err) {
      console.error(`err`, err)
      Toast.error(`Cron stop failed`)
    } finally {
      setIsLoading(false)
    }
  }

  const handleVolume = async () => {
    let response
    if (!volumeAmount) {
      return Toast.warning("Please enter the volume before start.")
    }
    if (data.length < 2) {
      return Toast.warning("Enter minimum 2 private keys.")
    }
    if (data[0].tokens?.length < 3) {
      return Toast.warning("Enter minimum 2 tokens.")
    }
    const privateKeysArray = data.map(entry => entry.private_key)
    const tokensArray = [...new Set(data.flatMap(entry => entry.tokens.map(token => token.token_address)))].slice(1)

    const requestData = {
      privateKeys: privateKeysArray,
      tokens: tokensArray,
      volumeAmount: volumeAmount,
    }
    console.log("requestData are", requestData)

    // return

    try {
      setLoading(true)
      response = await axios.post(`${URL_IN_USE}/start`, requestData)

      console.log("Response:", response)
      if (response.data !== null || undefined) {
        setLoading(false)
        setVolumeData("")
        return Toast.success(response.data.message)
      } else {
        setLoading(false)
        setVolumeData("")
        return Toast.error("Error while volume increase")
      }
    } catch (error) {
      console.error("Error sending data:", error)
      setLoading(false)
      return Toast.error("Cron start failed")
      return
    } finally {
      setLoading(false)
    }
  }

  const handleRowDelete = (private_key) => {
    const isDeleted = data?.filter((el) => el.private_key !== private_key)
    if (isDeleted) {
      localStorage.setItem("data", JSON.stringify(isDeleted))
      Toast.success("Private Key Deleted Successfully.")
      setTimeout(() => {
        window.location.reload()
      }, 1500)
    }
  }

  const handleColDelete = (token_address) => {
    if (!Array.isArray(data)) {
      console.error("Data is not an array or is undefined.")
      return
    }
    const updatedData = data.map((el) => {
      return {
        ...el,
        tokens: el.tokens?.filter((item) => item.token_address !== token_address),
      }
    })
    if (updatedData) {
      localStorage.setItem("data", JSON.stringify(updatedData))
      Toast.success("Token Deleted Successfully.")
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    }
  }
  return (
    <>
      <div className="container m-auto px-6 my-10 lg:p-4 lg:mt-16">
        <p className="text-3xl text-center lg:text-left md:text-4xl font-semibold">Automated Volume Bot</p>
        <div className="w-full flex flex-col lg:flex-row gap-4 lg:gap-12 items-center mt-8">
          <div className="w-full md:w-[90%] lg:w-[62%]">
            <label htmlFor="default-input" className="font-medium text-base md:text-xl text-[#373854]">
              Add address
            </label>
            <input
              type="text"
              id="default-input"
              value={selectAddress}
              onChange={(e) => setSelectAddress(e.target.value)}
              placeholder="Enter Private Key Or Token Address."
              className="bg-[#F9F9F9] mt-1 rounded-2xl text-sm md:text-base border-none outline-none text-[#373854] font-medium w-full p-4"
            />
          </div>
          <div className="w-full md:w-[90%] lg:w-[25%]">
            <label htmlFor="countries" className="font-medium text-base md:text-xl text-[#373854]">Type</label>
            <select
              onChange={(e) => setSelectValue(e.target.value)}
              id="countries"
              className="bg-[#F9F9F9] mt-1 rounded-2xl text-sm md:text-base border-none outline-none text-[#373854] font-medium w-full p-4"
            >
              <option value="private_key">Private key</option>
              <option value="token_address" disabled={data.length < 1}>Token address</option>
            </select>
          </div>
          <div className="w-full md:w-[90%] lg:w-[10%] xl:w-[8%] flex items-end">
            <button
              onClick={handleSubmit}
              type="button"
              className="text-[#ffffff] lg:mt-8 w-full text-base font-medium bg-[#713DEE] px-5 py-2.5 rounded-2xl"
            >
              Submit
            </button>
          </div>
        </div>

        {/* Render dynamic table */}
        {data.length > 0 ? (
          <div className="relative overflow-scroll drop-shadow-3xl h-[250px] md:h-[440px] rounded-lg my-10 lg:my-16">
            <table className="w-full text-sm">
              <thead className="text-xs text-white uppercase bg-[#FFFFFF] dark:text-white">
              <tr className="border-l-[1.4px] border-[#373854] border-opacity-20">
                <th
                  scope="col"
                  className="px-2 py-2 md:px-6 md:py-5 text-sm md:text-lg border-r-[1.4px] border-[#373854] border-opacity-20 text-opacity-50 text-[#373854]"
                >
                  No
                </th>
                <th
                  scope="col"
                  className="px-2 py-2 md:px-6 md:py-5 text-sm md:text-lg border-r-[1.4px] border-[#373854] border-opacity-20 text-opacity-50 text-[#373854]"
                >
                  Private Key
                </th>
                {data[0].tokens.map((_, index) => (
                  <th
                    key={index}
                    scope="col"
                    className="px-2 py-2 md:px-6 md:py-5 text-sm md:text-lg border-r-[1.4px] border-[#373854] border-opacity-20 text-opacity-50 text-[#373854]"
                  >
                    <p className="flex gap-3 items-center justify-center">
                      {data[0].tokens[index] && data[0].tokens[index]?.token_address === "GSYS" ? "GSYS" : `${data[0].tokens[index]?.token_address.slice(0, 4)}...${data[0].tokens[index]?.token_address.slice(-4)}`}
                      {data[0].tokens[index]?.token_address === "GSYS" ? "" :
                        <MdDelete className="cursor-pointer text-2xl"
                                  onClick={() => handleColDelete(data[0].tokens[index]?.token_address)} />}
                    </p>
                  </th>
                ))}
              </tr>
              </thead>
              <tbody>
              {data.map((entry, rowIndex) => (
                <tr key={rowIndex} className="border-[1.4px] border-[#373854] border-opacity-20">
                  <td
                    className="px-4 py-2 md:px-6 md:py-5 text-sm md:text-xl border-r-[1.4px] border-[#373854] border-opacity-20 font-medium text-[#373854] whitespace-nowrap"
                  >
                    {rowIndex + 1}
                  </td>
                  <td
                    className="px-4 py-2 md:px-6 md:py-5 text-sm md:text-xl border-r-[1.4px] border-[#373854] border-opacity-20 font-medium text-[#373854]"
                  >
                    <p className="flex gap-4 items-center"><MdDelete className="cursor-pointer text-2xl"
                                                                     onClick={() => handleRowDelete(entry.private_key)} /> {entry.private_key.slice(0, 4)}...{entry.private_key.slice(-4)}
                    </p>
                  </td>
                  {entry.tokens.map((token, colIndex) => (
                    <td
                      key={colIndex}
                      className="px-4 py-2 md:px-6 md:py-5 text-sm md:text-xl border-r-[1.4px] border-[#373854] border-opacity-20 font-medium text-[#373854]"
                    >
                      <p>{Number(token.balance)?.toFixed(4)}</p>
                    </td>
                  ))}
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="my-24 lg:my-48 text-center">
            <p className="text-lg md:text-3xl lg:text-5xl font-medium">Please Enter the Address For Data.</p>
          </div>
        )}

        <div className="flex flex-col lg:flex-row gap-2 md:gap-4 lg:mt-12">
          <div className="w-full lg:w-[50%] xl:w-[40%] 2xl:w-[30%]">
            <label htmlFor="default-input" className="font-medium text-base md:text-xl text-[#373854]">
              How much volume do you want to increase
            </label>
            <br />
            <label className="text-[#8B0000]">
              {/** Volume will increase every {volumeIncreaseInterval.includes("minutes") ? "minutes" : "hours"}*/}
              * Volume will increase every {volumeIncreaseInterval}
            </label>
            <input
              type="number"
              value={volumeAmount}
              onChange={handleChange}
              id="default-input"
              placeholder="Enter volume here."
              className="bg-[#F9F9F9] mt-1 rounded-2xl text-sm md:text-base border-none outline-none text-[#373854] font-medium w-full p-4"
            />
          </div>
          <div className="flex items-center">
            <button
              type="button"
              onClick={handleVolume}
              className="text-[#ffffff] lg:mt-8 text-base font-medium bg-[#713DEE] px-10 py-2.5 rounded-2xl"
            >
              {loading ?
                <Oval
                  visible={true}
                  height="30"
                  width="30"
                  color="#ffffff"
                  ariaLabel="oval-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  secondaryColor="#ffffff"
                /> : "Start"}
            </button>
            <button
              type="button"
              onClick={stopCron}
              className="text-[#ffffff] lg:mt-8 text-base font-medium bg-[#713DEE] px-10 py-2.5 rounded-2xl ml-4"
            >
              {isLoading ?
                <Oval
                  visible={true}
                  height="30"
                  width="30"
                  color="#ffffff"
                  ariaLabel="oval-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  secondaryColor="#ffffff"
                /> : "Stop"}
            </button>
          </div>
        </div>
      </div>
      <ToastComponent />
    </>
  )
}

export default App